<template>
  <div class="purgeDetails">
    <h1>Archived Record</h1>
    <div class="floating-footer">
      <div
        id="rightActions"
        class="float-foot-item float-foot-right trans-actions"
      >
        <v-btn color="green" @click="recordRestore()">
          <span class="white-text">Restore Record</span>
        </v-btn>
      </div>
    </div>
    <div class="panel">
      <displayVehicleData
        :processObj="detailsObj"
        :readOnly="true"
      ></displayVehicleData>
    </div>
  </div>
</template>

<script>
import vehicleFunctions from "@/mixins/vehicleFunctions";
import displayVehicleData from "@/components/nonPageComponents/DisplayVehicleData";
export default {
  name: "purgeDetails",
  data() {
    return {
      detailsObj:
        this.$store.getters.resultsArray[this.$store.getters.selectedIndex],
      vehicleTypesArray: this.$store.getters.vehicleTypes,
      colorsArray: this.$store.getters.vehicleColors,
      bodyTypesArray: this.$store.getters.bodyTypes,
      fuelArray: this.$store.getters.fuelTypes
    };
  },
  components: {
    displayVehicleData
  },
  mixins: [vehicleFunctions],
  methods: {
    recordRestore() {
       
      let processObj = JSON.parse(JSON.stringify(this.detailsObj));
      processObj =this.reformatTandrObjectforTransactionScreen(
        JSON.parse(JSON.stringify(processObj))
      );
     this.$store.commit("processObj", processObj);
     this.$store.commit("transactionType", "Restore Vehicle Record");
     this.$router.push({
        name: "Transaction",
        params: {
          menuIsShown: false,
          vin:this.detailsObj.vehicle.vin,
          make:this.detailsObj.vehicle.makeCode,
          year:
           this.detailsObj.vehicle.modelYear != undefined &&
           this.detailsObj.vehicle.modelYear != ""
              ?this.detailsObj.vehicle.modelYear
              : 0
        }
      });
    },
    sortLienholders: function (lienholders) {
      const sorted = lienholders.slice(0);
      sorted.sort(function (a, b) {
        const x = a.lienPrecedenceNo;
        const y = b.lienPrecedenceNo;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return sorted;
    }
  },
  async created() {
    this.detailsObj.transaction.transactionType = "";
    this.$root.$emit("setLoading", false);
  }
};
</script>

<style scoped>
.print {
  display: none;
}

.noPrint {
  display: inline;
}

.detailsTable td {
  vertical-align: top;
}

#left {
  width: 200px;
}

h4 {
  font-size: 18pt;
  display: inline;
  margin-right: 25px;
}

.subTable {
  border: 1px solid black;
  font-size: 80% !important;
  max-width: 100%;
}

@media print {
  .modal {
    width: 100%;
    height: 100%;
    margin: 0px;
    left: 0px;
  }

  #modalLoad {
    height: 100%;
    overflow-y: visible;
  }

  .print {
    display: inline;
    font-size: 9pt;
  }

  .noPrint {
    display: none;
  }

  hr {
    margin: 0px;
  }

  .label {
    font-size: 9pt;
  }
}
</style>
